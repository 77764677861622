import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";

// Icons
import { CloseIcon } from "../components/icons/close";

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  background-color: #252525;
  color: #ffffff;

  & .column {
    & p:first-of-type {
      margin-top: 0;
    }
  }

  & p {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.035em;
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;

    & .right-column {
      margin: 2em 0 0 0;
    }

    padding: 15px;
  }
`;
const About = ({ data }) => {
  return (
    <Page>
      <div
        className="left-column column"
        dangerouslySetInnerHTML={{
          __html: data.prismicAbout.data.column_left.html,
        }}
      />

      <div
        className="right-column column"
        dangerouslySetInnerHTML={{
          __html: data.prismicAbout.data.column_right.html,
        }}
      />

      <CloseIcon />
    </Page>
  );
};

export default withPreview(About);

export const query = graphql`
  {
    prismicAbout {
      data {
        column_left {
          html
        }
        column_right {
          html
        }
      }
    }
  }
`;
