import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Icon = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

export const CloseIcon = () => (
  <Icon>
    <Link to={`/`}>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 9.00006L1 17.0001L0.292894 16.293L8.29289 8.29295L1 1.00006L1.70711 0.292954L9 7.58585L16.2929 0.292954L17 1.00006L9.70711 8.29295L17.7071 16.293L17 17.0001L9 9.00006Z"
          fill="white"
        />
      </svg>
    </Link>
  </Icon>
);
